.landing-text h1{
    font-weight: 700;
}
img{
    aspect-ratio: attr(width) / attr(height);
    border-radius: 33px;
    padding: 1%;
  }

  .plaque {
    margin: 0 auto;
    background: #63bfb6;
    border: 1px solid #a7dbd6;
    box-shadow: 0 0 9px 9px #48b0a7;
    padding: 20px 40px 50px 40px;
    border-radius: 9px;
  }
 
  .btn {
    background: #265d58;
    -webkit-border-radius: 9;
    -moz-border-radius: 9;
    border-radius: 9px;
    border: 1px solid #4DB6AC;
    letter-spacing: 0.04em;
    color: #ffffffdd;
    font-size: 20px;
    padding: 14px 22px 14px 22px;
    text-decoration: none;
  }
  .btn:hover {
    background: #1f4b47;
    border: 1px solid #4DB6AC;
    text-decoration: none;
    cursor: pointer;
  }
  .btn:active {
    border: 1px solid #95d4ce;
    background-color: #2e6f69;
  }
  .btn:focus, #stringBox:focus {
    outline: none;
  }
  